import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

const Countdown = ({ date }) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef("");

  const startTimer = () => {
    const countdownDate = new Date(`${date}`);

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (days < 10) {
        days = `0${days}`;
      }
      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      // eslint-disable-next-line
      clearInterval(interval.current);
    };
  });

  return (
    <Flex
      mx="auto"
      w={{ base: "100%", md: "max-content" }}
      borderRadius="16px"
      align="center"
      pt="35px"
      px="15px"
      mt="20px"
    >
      <SimpleGrid
        maxW={{ base: "100%", md: "max-content" }}
        columns={{ base: "2", md: "4" }}
        gap="16px"
        w={{ base: "100%", md: "100%", lg: "100%" }}
      >
        <Flex
          direction="column"
          p={"25px 23px"}
          maxW={{ base: "100%", md: "168px" }}
          maxH={{ base: "100%", md: "168px" }}
          textAlign="center"
          border="1px solid"
          borderColor="#CBD5E0"
          borderRadius="16px"
        >
          <Text
            fontSize={{ base: "36px", md: "72px" }}
            color="#120F43"
            lineHeight={"100%"}
            mb="4px"
            fontWeight="bold"
          >
            {timerDays}
          </Text>
          <Text
            fontSize={{ base: "14px", lg: "18px" }}
            fontWeight="700"
            letterSpacing="0px"
            color="#120F43"
          >
            DAYS
          </Text>
        </Flex>
        <Flex
          direction="column"
          p={"25px 23px"}
          maxW={{ base: "100%", md: "168px" }}
          maxH={{ base: "100%", md: "168px" }}
          textAlign="center"
          border="1px solid"
          borderColor="#CBD5E0"
          borderRadius="16px"
        >
          <Text
            fontSize={{ base: "36px", md: "72px" }}
            color="#120F43"
            lineHeight={"100%"}
            mb="4px"
            fontWeight="bold"
          >
            {timerHours}
          </Text>
          <Text
            fontSize={{ base: "14px", lg: "18px" }}
            fontWeight="700"
            letterSpacing="0px"
            color="#120F43"
          >
            HOURS
          </Text>
        </Flex>
        <Flex
          direction="column"
          p={"25px 23px"}
          maxW={{ base: "100%", md: "168px" }}
          maxH={{ base: "100%", md: "168px" }}
          textAlign="center"
          border="1px solid"
          borderColor="#CBD5E0"
          borderRadius="16px"
        >
          <Text
            fontSize={{ base: "36px", md: "72px" }}
            color="#120F43"
            lineHeight={"100%"}
            mb="4px"
            fontWeight="bold"
          >
            {timerMinutes}
          </Text>
          <Text
            fontSize={{ base: "14px", lg: "18px" }}
            fontWeight="700"
            letterSpacing="0px"
            color="#120F43"
          >
            MINUTES
          </Text>
        </Flex>
        <Flex
          direction="column"
          p={"25px 23px"}
          maxW={{ base: "100%", md: "168px" }}
          maxH={{ base: "100%", md: "168px" }}
          textAlign="center"
          border="1px solid"
          borderColor="#CBD5E0"
          borderRadius="16px"
        >
          <Text
            fontSize={{ base: "36px", md: "72px" }}
            color="#120F43"
            lineHeight={"100%"}
            mb="4px"
            fontWeight="bold"
          >
            {timerSeconds}
          </Text>
          <Text
            fontSize={{ base: "14px", lg: "18px" }}
            fontWeight="700"
            letterSpacing="0px"
            color="#120F43"
          >
            SECONDS
          </Text>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};

export default Countdown;
