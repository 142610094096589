import { Flex, Image, SimpleGrid, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import logobfsmall from "assets/img/pro/black-friday/logo-bf-small.png";

const Countdown = ({ date }) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef("");

  const startTimer = () => {
    const countdownDate = new Date(`${date}`);

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (days < 10) {
        days = `0${days}`;
      }
      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      // eslint-disable-next-line
      clearInterval(interval.current);
    };
  });

  return (
    <Link href="#pricing" w="100%">
      <Flex
        bg="radial-gradient(58.11% 44.54% at 51.59% -9.61%, #B4B0FE 0%, #363285 22.92%, #110D5B 42.71%, #050327 88.54%);"
        w="100%"
        borderRadius="16px"
        align="center"
        py="35px"
        px="15px"
        direction={{ base: "column", lg: "row" }}
        position="relative"
        mb="20px"
      >
        <Image
          src={logobfsmall}
          me={{ lg: "10px", xl: "30px" }}
          w={{ base: "304px", lg: "200px" }}
          h={{ base: "130px", lg: "86px" }}
        />
        <Text
          fontSize={{ base: "16px" }}
          color="#fff"
          lineHeight={"26px"}
          fontWeight="500"
          letterSpacing="0px"
          h="max-content"
          me={{ lg: "10px", xl: "45px" }}
          textAlign={{ base: "center", lg: "left" }}
          maxW={{ base: "90%", md: "60%", lg: "270px", xl: "390px" }}
          mb={{ base: "20px", lg: "0px" }}
        >
          Take advantage of the Winter Special offers today and save up to 53% on
          your Horizon AI PRO order!
        </Text>
        <SimpleGrid
          maxW="max-content"
          columns={{ base: "2", md: "4" }}
          gap="10px"
          w={{ base: "335px", md: "510px", lg: "100%" }}
        >
          <Flex
            direction="column"
            p={"16px"}
            maxW="120px"
            maxH="120px"
            textAlign="center"
            border="1px solid"
            borderColor="whiteAlpha.300"
            borderRadius="10px"
          >
            <Text
              fontSize={{ base: "36px", md: "46px" }}
              color="#fff"
              lineHeight={"100%"}
              mb="4px"
              fontWeight="bold"
            >
              {timerDays}
            </Text>
            <Text
              fontSize={{ base: "14px", md: "14px" }}
              fontWeight="700"
              letterSpacing="0px"
              w="100%"
              color="#fff"
            >
              DAYS
            </Text>
          </Flex>
          <Flex
            direction="column"
            p={"16px"}
            maxW="120px"
            maxH="120px"
            textAlign="center"
            border="1px solid"
            borderColor="whiteAlpha.300"
            borderRadius="10px"
          >
            <Text
              fontSize={{ base: "36px", md: "46px" }}
              color="#fff"
              lineHeight={"100%"}
              mb="4px"
              fontWeight="bold"
            >
              {timerHours}
            </Text>
            <Text
              fontSize={{ base: "14px", md: "14px" }}
              fontWeight="700"
              letterSpacing="0px"
              w="100%"
              color="#fff"
            >
              HOURS
            </Text>
          </Flex>
          <Flex
            direction="column"
            p={"16px"}
            maxW="120px"
            maxH="120px"
            textAlign="center"
            border="1px solid"
            borderColor="whiteAlpha.300"
            borderRadius="10px"
          >
            <Text
              fontSize={{ base: "36px", md: "46px" }}
              color="#fff"
              lineHeight={"100%"}
              mb="4px"
              fontWeight="bold"
            >
              {timerMinutes}
            </Text>
            <Text
              fontSize={{ base: "14px", md: "14px" }}
              fontWeight="700"
              letterSpacing="0px"
              w="100%"
              color="#fff"
            >
              MINUTES
            </Text>
          </Flex>
          <Flex
            direction="column"
            p={"16px"}
            maxW="120px"
            maxH="120px"
            textAlign="center"
            border="1px solid"
            borderColor="whiteAlpha.300"
            borderRadius="10px"
          >
            <Text
              fontSize={{ base: "36px", md: "46px" }}
              color="#fff"
              lineHeight={"100%"}
              mb="4px"
              fontWeight="bold"
            >
              {timerSeconds}
            </Text>
            <Text
              fontSize={{ base: "14px", md: "14px" }}
              fontWeight="700"
              letterSpacing="0px"
              w="100%"
              color="#fff"
            >
              SECONDS
            </Text>
          </Flex>
        </SimpleGrid>
      </Flex>
    </Link>
  );
};

export default Countdown;
