import { Flex, Image, Icon, Text, Link } from "@chakra-ui/react";
import React from "react";
import exclusivebundle from "assets/img/pro/black-friday/exclusive-bundle.png";
import ribbon from "assets/img/pro/black-friday/golden-ribbon.png";
import Typescript from "assets/img/pro/typescript-logo-image.png";
import Javascript from "assets/img/pro/javascript-logo-image.png";
import ChakraLogo from "assets/img/pro/chakra-logo.png";
import ReactLogo from "assets/img/pro/react-logo-image.png";
import Tailwind from "assets/img/pro/tailwindcss-logo-image.png";
import NextJS from "assets/img/pro/nextjs-logo-image.png";
import { MdAdd } from "react-icons/md";

const Bundles = () => {
  return (
    <Link w="100%" href="https://horizon-ui.com/campaign#bundle">
      <Flex
        bg="radial-gradient(58.11% 44.54% at 51.59% -9.61%, #B4B0FE 0%, #363285 22.92%, #110D5B 42.71%, #050327 88.54%);"
        w="full"
        borderRadius="16px"
        align="center"
        py="35px"
        direction={{ base: "column", lg: "row" }}
        px="0px"
        position="relative"
        mb={{ base: "100px", lg: "150px" }}
      >
        <Image
          src={exclusivebundle}
          w={{ base: "400px", lg: "293px" }}
          me={{ lg: "24px", xl: "20px" }}
          mb={{ base: "30px", lg: "0px" }}
        />
        <Flex
          flexWrap="wrap"
          align={"center"}
          maxW={{ base: "300px", lg: "240px", xl: "300px" }}
          justifyContent="center"
          gap={{ base: "8px", md: "14px" }}
          rowGap="20px"
        >
          <Image
            maxW={{ base: "40px", lg: "30px", xl: "40px" }}
            src={ReactLogo}
          />
          <Flex
            justify={"center"}
            align="center"
            minW="26px"
            h="26px"
            w="26px"
            p="4px"
            border="2px solid"
            borderColor="rgba(255, 255, 255, 0.1)"
            borderRadius="100%"
            bg="rgba(255, 255, 255, 0.12)"
          >
            <Icon
              ms="-1px"
              as={MdAdd}
              w="18px"
              h="18px"
              color="white"
              mt="1px"
            />
          </Flex>
          <Image
            maxW={{ base: "40px", lg: "30px", xl: "40px" }}
            src={ChakraLogo}
          />
          <Flex
            justify={"center"}
            align="center"
            minW="26px"
            h="26px"
            w="26px"
            p="4px"
            border="2px solid"
            borderColor="rgba(255, 255, 255, 0.1)"
            borderRadius="100%"
            bg="rgba(255, 255, 255, 0.12)"
          >
            <Icon
              ms="-1px"
              as={MdAdd}
              w="18px"
              h="18px"
              color="white"
              mt="1px"
            />
          </Flex>
          <Image
            maxW={{ base: "40px", lg: "30px", xl: "40px" }}
            src={Tailwind}
          />
          <Flex
            justify={"center"}
            align="center"
            minW="26px"
            h="26px"
            w="26px"
            p="4px"
            border="2px solid"
            borderColor="rgba(255, 255, 255, 0.1)"
            borderRadius="100%"
            bg="rgba(255, 255, 255, 0.12)"
          >
            <Icon
              ms="-1px"
              as={MdAdd}
              w="18px"
              h="18px"
              color="white"
              mt="1px"
            />
          </Flex>
          <Image
            maxW={{ base: "40px", lg: "30px", xl: "40px" }}
            src={Typescript}
          />
          <Flex
            justify={"center"}
            align="center"
            minW="26px"
            h="26px"
            w="26px"
            p="4px"
            border="2px solid"
            borderColor="rgba(255, 255, 255, 0.1)"
            borderRadius="100%"
            bg="rgba(255, 255, 255, 0.12)"
          >
            <Icon
              ms="-1px"
              as={MdAdd}
              w="18px"
              h="18px"
              color="white"
              mt="1px"
            />
          </Flex>
          <Image
            maxW={{ base: "40px", lg: "30px", xl: "40px" }}
            src={Javascript}
          />
          <Flex
            justify={"center"}
            align="center"
            minW="26px"
            h="26px"
            w="26px"
            p="4px"
            border="2px solid"
            borderColor="rgba(255, 255, 255, 0.1)"
            borderRadius="100%"
            bg="rgba(255, 255, 255, 0.12)"
          >
            <Icon
              ms="-1px"
              as={MdAdd}
              w="18px"
              h="18px"
              color="white"
              mt="1px"
            />
          </Flex>
          <Image maxW={{ base: "40px", lg: "30px", xl: "40px" }} src={NextJS} />
        </Flex>

        <Text
          fontSize={{ base: "16px", lg: "14px" }}
          color="#fff"
          lineHeight={"24px"}
          fontWeight="500"
          letterSpacing="0px"
          h="max-content"
          ms={{ lg: "10px", xl: "20px" }}
          me={{ lg: "10px", xl: "20px" }}
          textAlign={{ base: "center", lg: "left" }}
          maxW={{ base: "90%", md: "60%", lg: "270px", xl: "290px" }}
          mt={{ base: "40px", lg: "0px" }}
          mb={{ base: "20px", lg: "0px" }}
        >
          Expand your project horizons by getting all versions of Horizon UI PRO
          at 50% OFF with our Exclusive and Limited bundle offer!
        </Text>
        <Image
          display={{ base: "none", md: "flex" }}
          src={ribbon}
          w="210px"
          right={{ md: "-18px", lg: "-18px", xl: "40px" }}
          top="-44px"
          position={"absolute"}
        />
      </Flex>
    </Link>
  );
};

export default Bundles;
